import { AnimatedSection, SlideShow } from '@elements';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, Text, Flex } from '@topremit/shared-web/components/elements';
import { Angle } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { Ref, useState } from 'react';

import ServiceTestimonyItem from './ServiceTestimonyItem';

const SERVICE_TESTIMONY_ITEM_LENGTH = 5;

const { desktopXl, desktopMd, tabletMd, tabletSm, mobileLg } = screenSize;

function Testimony() {
  const { t } = useTranslation('home');
  const [prevButtonRef, setPrevButtonRef] = useState();
  const [nextButtonRef, setNextButtonRef] = useState();

  const [step, setStep] = useState(0);

  function renderServiceTestimonyItem() {
    return Array.from({ length: SERVICE_TESTIMONY_ITEM_LENGTH }).map(
      (_, idx) => <ServiceTestimonyItem key={idx + 1} step={idx + 1} />,
    );
  }

  return (
    <AnimatedSection id="testimony" viewAmount={0.35} className={styled.root}>
      <Flex column className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2">
          {t('testimony.title')}
        </Text>
        <div className="list-testimony">
          <Button
            type="tertiary"
            ref={setPrevButtonRef as unknown as Ref<HTMLButtonElement>}
            className="action-button-left"
            shape="round"
            size="medium"
            leftIcon={
              <Angle direction="left" size="small" fill="var(--blue-500)" />
            }
          />
          <SlideShow
            repeatStep
            draggable
            value={step}
            indicator
            totalData={SERVICE_TESTIMONY_ITEM_LENGTH}
            prevButton={prevButtonRef}
            nextButton={nextButtonRef}
            onChange={(val) => setStep(val)}
          >
            {renderServiceTestimonyItem()}
          </SlideShow>
          <Button
            type="tertiary"
            ref={setNextButtonRef as unknown as Ref<HTMLButtonElement>}
            shape="round"
            className="action-button-right"
            size="medium"
            leftIcon={
              <Angle direction="right" size="small" fill="var(--blue-500)" />
            }
          />
        </div>
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      position: relative;
      align-items: center;
      width: unset;
      max-width: 72.5rem;
      padding: 6.25rem 6.1875rem;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem clamp(2.5rem, -1.7149rem + 8.781vw, 6.188rem);
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
        flex-direction: column;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      > h2 {
        text-align: center;
        text-transform: uppercase;
        font-weight: var(--black-font-weight);
        margin-bottom: 3.5rem;
        @media (max-width: ${desktopMd}px) {
          margin-bottom: 2.5rem;
        }
      }
      .list-testimony {
        display: flex;
        align-items: center;
        position: relative;
        .action-button-left,
        .action-button-right {
          min-width: 2.5rem !important;
          height: 2.5rem !important;
          box-shadow: 0px 2px 8px 0px rgba(46, 72, 101, 0.1);
          @media (max-width: ${tabletSm}px) {
            position: absolute;
            z-index: 1;
          }
        }
        .action-button-left {
          margin-right: 0.5rem;
          @media (max-width: ${tabletSm}px) {
            left: -0.5rem;
          }
        }
        .action-button-right {
          margin-left: 0.5rem;
          @media (max-width: ${tabletSm}px) {
            right: -0.5rem;
            margin-left: unset;
          }
        }
        .slide-show-indicator {
          margin-top: 0.5rem;
        }
        > .slide-show {
          > .slide-show-outer {
            > .slide-show-inner {
              padding: 2.625rem 1rem 1rem;
              @media (max-width: ${mobileLg}px) {
                padding: 2.625rem 0.5rem 1rem;
              }
              > .slide-show-content {
                border-radius: 30px;
                box-shadow: 0px 0px 16px rgba(46, 72, 101, 0.08);
              }
            }
          }
        }
      }
    }
  `,
};

export default Testimony;
