import { useGetFAQ } from '@api-hooks/transaction';
import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import { useClickFaqHref } from '@hooks';
import { screenSize } from '@topremit/shared-web/common/size';
import { Card, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useCallback } from 'react';

import ServiceFaqItem from './ServiceFaqItem';

const { mobileLg, tabletMd, desktopXl } = screenSize;

export default function Faq() {
  const { t, lang } = useTranslation('home');

  const { data: faq } = useGetFAQ();

  useClickFaqHref();

  const renderServiceFaqItem = useCallback(
    () =>
      faq?.data.map(({ title, body }, idx) => (
        <ServiceFaqItem key={idx} title={title} body={body} />
      )),
    [faq, lang],
  );

  return (
    <AnimatedSection id="faq" viewAmount={0.35} className={styled.root}>
      <div className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2">
          {t('faq.title')}
        </Text>
        <Card column variant="outline" className="list-faq">
          {renderServiceFaqItem()}
        </Card>
      </div>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      align-items: center;
      width: unset;
      max-width: 72.5rem;
      padding: 6.25rem 6.1875rem;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem clamp(2.5rem, -1.7149rem + 8.781vw, 6.188rem);
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
        flex-direction: column;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      h2 {
        max-width: 57rem;
        text-align: center;
        margin-bottom: 2.5rem;
        text-transform: uppercase;
        font-weight: var(--black-font-weight);
        @media (max-width: ${mobileLg}px) {
          margin-bottom: 2.5rem;
        }
      }
      .list-faq {
        padding: 0;
        width: 100%;
        max-width: 57rem;
        margin-bottom: 3rem;
        border-radius: 16px;
        > :not(:last-child) {
          ::after {
            content: '';
            position: absolute;
            left: 1.5rem;
            right: 1.5rem;
            bottom: 0;
            height: 1px;
            background-color: var(--neutral-200);
          }
        }
        > div {
          &:first-child {
            border-radius: 16px 16px 0 0;
          }
          &:last-child {
            border-radius: 0 0 16px 16px;
          }
        }
      }
    }
  `,
};
