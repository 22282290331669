import { useGetMeta } from '@api-hooks/common';
import { useGetFAQ } from '@api-hooks/transaction';
import { removeHTMLTags } from '@topremit/shared-web/common/helper';
import { useMemo } from 'react';

import Countries from './Countries';
import FeaturedOn from './FeaturedOn';
import NoHiddenFees from './NoHiddenFees';
import ReadyToSend from './ReadyToSend';
import ReliableSupport from './ReliableSupport';
import Award from './award/Award';
import Faq from './faq/Faq';
import How from './how/How';
import Jumbotron from './jumbotron/Jumbotron';
import Services from './services/Services';
import Testimony from './testimony/Testimony';
import Why from './why/Why';

export default function Landing() {
  const { data } = useGetMeta();
  const { data: faq } = useGetFAQ();

  const FAQ = useMemo(
    () =>
      JSON.stringify(
        faq?.data.map(({ body, title }) => {
          return {
            '@type': 'Question',
            name: title,
            acceptedAnswer: {
              '@type': 'Answer',
              text: removeHTMLTags(body),
            },
          };
        }),
      ),
    [faq],
  );

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Topremit",
            "operatingSystem": "ANDROID, iOS",
            "applicationCategory": "FinanceApplication",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": ${data?.data.reviewRate},
              "ratingCount": ${data?.data.totalReview},
              "worstRating": 1,
              "bestRating": 5
              },
            "offers": {
              "@type": "Offer",
              "price": "0",
              "category": "free"
              }
            }
          `,
        }}
        id="google-review-snippets"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": ${FAQ}
              }
            `,
        }}
        id="faq-page"
      />
      <Jumbotron />
      <Award />
      <Why />
      <Countries />
      <Services />
      <How />
      <NoHiddenFees />
      <ReliableSupport />
      <FeaturedOn />
      <Testimony />
      <ReadyToSend />
      <Faq />
    </>
  );
}
