import { useGetMeta } from '@api-hooks/common';
import { css } from '@emotion/css';
import awardRibbonImage from '@public/images/svg/award-ribbon.svg';
import awardThumbImage from '@public/images/svg/award-thumb.svg';
import awardTrophyImage from '@public/images/svg/award-trophy.svg';
import { screenSize } from '@topremit/shared-web/common/size';
import { parseHtml, Text } from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';

const awardImages = [awardRibbonImage, awardThumbImage, awardTrophyImage];

interface IAwardItemProps {
  idx: number;
}

export default function AwardItem({ idx }: IAwardItemProps) {
  const { t } = useTranslation('home');
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);
  const { data: meta } = useGetMeta();

  return (
    <div className={styled.root}>
      <div className="illustration" data-nosnippet>
        <Image
          src={awardImages[idx]}
          width={isMobileSize ? 32 : 40}
          height={isMobileSize ? 32 : 40}
          alt={t(`image_alt.award.${idx + 1}`)}
        />
      </div>
      <Text as="span" minSize="sm" maxSize="p" className="description">
        {parseHtml(
          t(`jumbotron.award.card_title_${idx + 1}`, {
            totalUser: meta?.data.lifetimeMember,
          }),
        )}
      </Text>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-basis: 33%;

    @media (max-width: ${screenSize.tabletSm}px) {
      .description {
        padding: 8px 0;
      }
    }

    .illustration {
      padding: 0.25rem;
      margin-right: 1rem;
    }
  `,
};
