import { useGetAllContinents, useGetMeta } from '@api-hooks/common';
import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Flex,
  ListDropdown,
  Text,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

import CountryItem from '../countries/CountryItem';

const { desktopXl, tabletLg, tabletMd, tabletSm, mobileLg } = screenSize;

export default function Countries() {
  const { t } = useTranslation();
  const { data: meta } = useGetMeta();

  const { data } = useGetAllContinents();

  const continents = data?.data || {};

  const renderCountries = (countries) =>
    countries.map(({ flagCode, name, isoCode, urlSlug }) => (
      <CountryItem
        key={isoCode}
        flagCode={flagCode}
        name={name}
        isoCode={isoCode}
        urlSlug={urlSlug}
      />
    ));

  const renderContinents = () =>
    Object.entries(continents).map(([continent, countries]) => (
      <ListDropdown
        key={continent}
        title={
          <Text as="h3" minSize="p" maxSize="h5">
            {t(`home:countries.continents.${continent}`)}
          </Text>
        }
        content={renderCountries(countries)}
        minItems={3}
        contentClassName="country-list"
      />
    ));

  return (
    <AnimatedSection
      id="country-list"
      viewAmount={0.35}
      className={styled.root}
    >
      <Flex column className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2" className="title">
          {t('home:countries.title', {
            total_country: meta?.data.countrySum,
            total_currencies: meta?.data.totalCurrencies,
          })}
        </Text>
        <div className="continents-wrapper">{renderContinents()}</div>
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      max-width: 72.5rem;
      width: unset;
      padding: 6.25rem 0;
      margin: 0 auto;
      align-items: center;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem
          calc(2.5rem - clamp(0rem, -2.8571rem + 5.9524vw, 2.5rem));
      }
      @media (max-width: ${tabletMd}px) {
        padding: 4.5rem 2.5rem;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      h2 {
        text-align: center;
        text-transform: uppercase;
        font-weight: var(--black-font-weight);
        margin-bottom: 2.5rem;
        @media (min-width: 275px) and (max-width: 525px) {
          width: 14rem;
        }
      }
      .continents-wrapper {
        max-width: 60.9375rem;
        width: 100%;
        > :not(:last-child) {
          margin-bottom: 1rem;
        }
        .title {
          /* text-transform: capitalize; */
          h3 {
            margin-bottom: 0;
          }
        }
        .country-list {
          display: grid;
          grid-template-columns: repeat(3, minmax(8.9375rem, 14.5625rem));
          align-items: center;
          row-gap: 1rem;
          column-gap: 1rem;
          @media (max-width: ${tabletLg}px) {
            row-gap: 0.5rem;
            column-gap: 0.5rem;
          }
          @media (max-width: 715px) {
            grid-template-columns: repeat(2, minmax(8.9375rem, 14.5625rem));
          }
          @media (max-width: ${tabletSm}px) {
            grid-template-columns: auto;
          }
        }
      }
    }
  `,
};
