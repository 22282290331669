import { AnimatedSection, SlideShow } from '@elements';
import { css } from '@emotion/css';
import howStepEn1Image from '@public/images/png/send-money-how-step-en-1.png';
import howStepEn2Image from '@public/images/png/send-money-how-step-en-2.png';
import howStepEn3Image from '@public/images/png/send-money-how-step-en-3.png';
import howStepEn4Image from '@public/images/png/send-money-how-step-en-4.png';
import howStepEn5Image from '@public/images/png/send-money-how-step-en-5.png';
import howStepId1Image from '@public/images/png/send-money-how-step-id-1.png';
import howStepId2Image from '@public/images/png/send-money-how-step-id-2.png';
import howStepId3Image from '@public/images/png/send-money-how-step-id-3.png';
import howStepId4Image from '@public/images/png/send-money-how-step-id-4.png';
import howStepId5Image from '@public/images/png/send-money-how-step-id-5.png';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, Flex, Text } from '@topremit/shared-web/components/elements';
import { Angle } from '@topremit/shared-web/components/shapes';
import {
  useCallbackRef,
  useMediaQuery,
  useTranslation,
} from '@topremit/shared-web/hooks';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { Fragment, useState } from 'react';

const ServiceHowCard = dynamic(() => import('./ServiceHowCard'));
const ServiceHowItem = dynamic(() => import('./ServiceHowItem'));

const { mobileMd, mobileLg, tabletMd, desktopMd, desktopLg, desktopXl } =
  screenSize;

const howStepImage = {
  id: [
    howStepId1Image,
    howStepId2Image,
    howStepId3Image,
    howStepId4Image,
    howStepId5Image,
  ],
  en: [
    howStepEn1Image,
    howStepEn2Image,
    howStepEn3Image,
    howStepEn4Image,
    howStepEn5Image,
  ],
};

const SERVICE_HOW_ITEM_LENGTH = 5;
const SLIDESHOW_AUTOPLAY_TIME = 10000;

export default function How() {
  const { t, lang } = useTranslation();
  const [prevButtonRef, setPrevButtonRef] = useCallbackRef();
  const [nextButtonRef, setNextButtonRef] = useCallbackRef();

  const [step, setStep] = useState(0);
  const isFirstStep = step === 0;
  const isLastStep = step === SERVICE_HOW_ITEM_LENGTH - 1;

  const isMobileDesign = useMediaQuery(`(max-width: ${desktopLg}px)`);

  function renderIllustration() {
    return Array.from({ length: SERVICE_HOW_ITEM_LENGTH }).map((_, idx) => (
      <Fragment key={`how-keys-${idx}`}>
        <div className="illustration">
          <div className="illustration-image" data-nosnippet>
            <Image
              fill
              placeholder="blur"
              src={howStepImage[lang]?.[idx]}
              alt={t(`home:image_alt.how.${idx + 1}`)}
              onDragStart={(e) => e.preventDefault()}
              className="object-fit-contain"
              sizes="29.25rem"
            />
          </div>
        </div>
        {isMobileDesign && (
          <div className="how-item">
            <ServiceHowItem step={idx + 1} />
          </div>
        )}
      </Fragment>
    ));
  }

  function renderServiceHowItem() {
    return Array.from({ length: SERVICE_HOW_ITEM_LENGTH }).map((_, idx) => (
      <ServiceHowCard
        key={`service-how-keys-${idx + 1}`}
        step={idx + 1}
        setStep={setStep}
        currentStep={step + 1}
      />
    ));
  }

  return (
    <AnimatedSection id="how" viewAmount={0.35} className={styled.root}>
      <Flex column className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2">
          {t('home:how.title')}
        </Text>
        <div className="content">
          <div className="left">
            <SlideShow
              draggable
              indicator={isMobileDesign}
              value={step}
              totalData={SERVICE_HOW_ITEM_LENGTH}
              prevButton={prevButtonRef}
              nextButton={nextButtonRef}
              onChange={(val) => setStep(val)}
              repeatStep={!isMobileDesign}
              autoPlay={!isMobileDesign}
              autoPlayTime={SLIDESHOW_AUTOPLAY_TIME}
              actionButtonPrev={
                <Button
                  type="invert"
                  size="medium"
                  disabled={isFirstStep}
                  ref={(e) => setPrevButtonRef(e)}
                  className="action-button-left card card-main"
                  shape="round"
                  style={{
                    borderRadius: '100%',
                  }}
                  leftIcon={
                    <Angle
                      direction="left"
                      size="small"
                      width={24}
                      height={24}
                      fill="var(--blue-500)"
                    />
                  }
                />
              }
              actionButtonNext={
                <Button
                  type="invert"
                  size="medium"
                  shape="round"
                  disabled={isLastStep}
                  ref={(e) => setNextButtonRef(e)}
                  className="action-button-right card card-main"
                  style={{
                    borderRadius: '100%',
                  }}
                  leftIcon={
                    <Angle
                      direction="right"
                      size="small"
                      width={24}
                      height={24}
                      fill="var(--blue-500)"
                    />
                  }
                />
              }
              showActionButton={isMobileDesign}
            >
              {renderIllustration()}
            </SlideShow>
          </div>
          {!isMobileDesign && (
            <div className="right">
              <dl>{renderServiceHowItem()}</dl>
            </div>
          )}
        </div>
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      align-items: center;
      width: unset;
      max-width: 72.5rem;
      padding: 6.25rem 6.1875rem;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem clamp(2.5rem, -1.7149rem + 8.781vw, 6.188rem);
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
        flex-direction: column;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
    }
    h2 {
      font-weight: var(--black-font-weight);
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 2.5rem;
      @media (max-width: ${tabletMd}px) {
        max-width: 100%;
        letter-spacing: 0;
        margin-bottom: 2.5rem;
      }
      @media (max-width: ${mobileLg}px) {
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      width: 100%;
      > .left {
        position: relative;
        display: flex;
        width: 100%;
        height: 33.5rem;
        max-width: 29.25rem;
        margin-right: 1.5rem;
        justify-content: center;
        @media (max-width: ${desktopXl}px) {
          height: auto;
          flex-direction: column;
        }
        @media (max-width: ${desktopLg}px) {
          margin-right: 0;
        }

        .slide-show-outer {
          width: 100%;
          .slide-show-inner {
            .slide-show-content {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
        .slide-show-indicator {
          margin-top: 0;
        }

        .illustration {
          position: relative;
          display: flex;
          justify-content: center;
          width: 29.25rem;
          height: 33.5rem;
          @media (max-width: ${desktopLg}px) {
            margin-bottom: 2.5rem;
          }
          @media (max-width: 680px) {
            width: 20.4375rem;
            height: 25rem;
          }
          @media (max-width: 510px) {
            width: 18.125rem;
            height: 17.5rem;
          }
          @media (max-width: ${mobileLg}px) {
            width: 14.6875rem;
          }
          @media (max-width: ${mobileMd}px) {
            width: 13.75rem;
            height: 13.75rem;
          }
        }
        .illustration-image {
          position: relative;
          display: flex;
          width: 100%;
          height: 100%;
          max-width: 29.25rem;
          max-height: 33.5rem;
          z-index: 1;
        }
        .how-item {
          width: 100%;
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 29.3125rem;
        dl {
          margin: 0;
          > div:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
      .action-button-left,
      .action-button-right {
        display: flex;
        position: absolute;
        z-index: 2;
        border: unset !important;
        top: 15.25rem !important;
        @media (max-width: 680px) {
          top: 11rem !important;
        }
        @media (max-width: 510px) {
          top: 7.25rem !important;
        }
        @media (max-width: ${mobileMd}px) {
          top: 5.375rem !important;
        }
        &:disabled {
          opacity: 0;
        }
      }
      .action-button-left {
        left: -9.375rem;
        @media (max-width: ${desktopMd}px) {
          left: -6.25rem;
        }
        @media (max-width: ${tabletMd}px) {
          left: -3.125rem;
        }
        @media (max-width: 620px) {
          left: 0;
        }
      }
      .action-button-right {
        right: -9.375rem;
        @media (max-width: ${desktopMd}px) {
          right: -6.25rem;
        }
        @media (max-width: ${tabletMd}px) {
          right: -3.125rem;
        }
        @media (max-width: 620px) {
          right: 0;
        }
      }
    }
  `,
};
