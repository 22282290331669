import { css } from '@emotion/css';
import testimonyUser1Image from '@public/images/png/testimony-user-1.png';
import testimonyUser2Image from '@public/images/png/testimony-user-2.png';
import testimonyUser3Image from '@public/images/png/testimony-user-3.png';
import testimonyUser4Image from '@public/images/png/testimony-user-4.png';
import testimonyUser5Image from '@public/images/png/testimony-user-5.png';
import squareQuotationMarkImage from '@public/images/svg/square-quotation-mark.svg';
import { RatingStar } from '@shapes';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Card,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';
import Trans from 'next-translate/Trans';

const testimonyUserImages = [
  testimonyUser1Image,
  testimonyUser2Image,
  testimonyUser3Image,
  testimonyUser4Image,
  testimonyUser5Image,
];

const { desktopMd, tabletMd, mobileLg } = screenSize;

interface IServiceTestimonyItemProps {
  step: number;
}

/**
 * @deprecated refactor global component to use together with send-money page and mobile top up
 */
export default function ServiceTestimonyItem({
  step,
}: IServiceTestimonyItemProps) {
  const { t } = useTranslation('home');

  function renderStars(length: number) {
    return Array.from({ length }).map((_, idx) => (
      <RatingStar key={idx} type="gradient" className="icon-star" />
    ));
  }

  return (
    <Card borderRadius={16} className={styled.root}>
      <div className="image-container">
        <Image
          src={testimonyUserImages[step - 1]}
          placeholder="blur"
          quality={50}
          width={82}
          height={82}
          draggable={false}
          className="testimony-image object-fit-contain"
          alt={`testimony image ${step}`}
        />
        <div className="user-info">
          <Text className="name bold-text">
            {t(`testimony.user.name_${step}`)}
          </Text>
          <div className="rating">
            <div className="rating-stars">{renderStars(5)}</div>
          </div>
        </div>
      </div>
      <div className="right">
        <Text minSize="h5" maxSize="h4" className="highlight bold-text">
          <Trans
            i18nKey={`home:testimony.user.highlight_${step}`}
            components={[
              <Image
                src={squareQuotationMarkImage}
                height={22}
                alt="quotation mark"
                className="open-quotation-mark"
              />,
              <Image
                src={squareQuotationMarkImage}
                height={22}
                alt="quotation mark"
                className="close-quotation-mark"
              />,
            ]}
          />
        </Text>
        <Text as="p" minSize="xs" maxSize="p" className="comment">
          {parseHtml(t(`home:testimony.user.comment_${step}`))}
        </Text>
        <Text as="span" minSize="xxs" maxSize="xs" className="note">
          {parseHtml(t('testimony.note'))}
        </Text>
      </div>
    </Card>
  );
}

const styled = {
  root: css`
    position: relative;
    display: flex;
    max-width: 40.3125rem;
    height: 25rem;
    padding: 6.625rem 2rem 2rem;
    justify-content: center;
    overflow: unset;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: ${tabletMd}px) {
      height: 28.125rem;
      padding-top: 7.1875rem;
    }
    .image-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: -2.625rem;
      .testimony-image {
        border-radius: 100%;
        margin-bottom: 0.5rem;
        border: 4px solid var(--neutral-0);
      }
      .user-info {
        .name {
          text-align: center;
          @media (max-width: ${tabletMd}px) {
            margin-bottom: 0.25rem;
          }
        }
        .rating {
          display: flex;
          align-items: center;
          .rating-stars {
            .icon-star {
              &:not(:last-child) {
                margin-right: 0.385625rem;
              }
            }
          }
          .rating-text {
            color: var(--text-secondary);
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      height: 100%;
      @media (max-width: ${desktopMd}px) {
        margin-left: 0;
      }
      .highlight {
        display: inline;
        margin-bottom: 1.5rem;
        text-align: center;
        @media (min-width: ${mobileLg}px) and (max-width: 434px) {
          padding: 0 8px;
        }
        @media (min-width: 370px) and (max-width: 383px) {
          padding: 0 8px;
        }
        .open-quotation-mark,
        .close-quotation-mark {
          width: 0.625rem;
        }
        .open-quotation-mark {
          margin-right: 0.25rem;
        }
        .close-quotation-mark {
          transform: scale(-1, -1);
          margin-left: 0.25rem;
        }
      }
      .comment {
        margin-top: 0;
        margin-bottom: auto;
      }
      .note {
        color: var(--text-secondary);
        text-align: center;
      }
    }
  `,
};
