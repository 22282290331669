import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import coinRightImage from '@public/images/png/coin-ready-to-send-money-right.png';
import coinLeftImage from '@public/images/png/coin-send-money-left.png';
import paperPlaneImage from '@public/images/png/paper-plane-lightblue.png';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Button,
  Flex,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';
import Link from 'next/link';

const { desktopXl, desktopSm, tabletMd, mobileLg } = screenSize;

export default function ReadyToSend() {
  const { t, lang } = useTranslation('home');
  const isSmallDesktopSize = useMediaQuery(
    `(max-width:${screenSize.desktopSm}px)`,
  );
  const isMobileSize = useMediaQuery(`(max-width:${tabletMd}px)`);

  return (
    <AnimatedSection
      id="ready-to-send"
      viewAmount={0.35}
      className={styled.root}
    >
      <Flex className="ready-to-send-wrapper">
        <div className="send-money">
          <Text as="h2" minSize="h3" maxSize="h1" className="title">
            {parseHtml(t('ready_to_send.title'))}
          </Text>
          <Link href={`${lang}/register/create-account`}>
            <Button type="secondary" size={isMobileSize ? 'small' : 'large'}>
              {t('ready_to_send.send_now')}
            </Button>
          </Link>
        </div>
        <div className="illustration">
          <div className="left-coin">
            <Image
              src={coinLeftImage}
              alt="left coin"
              height={isMobileSize ? 60 : isSmallDesktopSize ? 80 : 100}
            />
          </div>
          <div className="illustration-section">
            <div className="right-coin">
              <Image
                src={coinRightImage}
                alt="right coin"
                height={isMobileSize ? 40 : isSmallDesktopSize ? 55 : 70}
              />
            </div>
            <div className="paper-plane">
              <Image
                src={paperPlaneImage}
                alt="paper plane"
                height={isMobileSize ? 150 : isSmallDesktopSize ? 210 : 270}
              />
            </div>
          </div>
        </div>
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    background: var(--blue-500);
    .ready-to-send-wrapper {
      align-items: center;
      width: unset;
      max-width: 72.5rem;
      padding: 6.25rem 0;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem
          calc(2.5rem - clamp(0rem, -2.8571rem + 5.9524vw, 2.5rem));
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
        flex-direction: column;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      .send-money {
        display: flex;
        flex-direction: column;
        @media (max-width: ${tabletMd}px) {
          align-items: center;
          margin-bottom: 2.5rem;
        }
        .title {
          color: var(--neutral-0);
          width: 75%;
          max-width: 47rem;
          text-align: left;
          line-height: 32px;
          line-height: clamp(32px, 21px + 3.055555555555556vw, 65px);
          font-weight: var(--black-font-weight);
          margin-bottom: 1.75rem;
          text-transform: uppercase;
          @media (max-width: ${tabletMd}px) {
            margin-bottom: 0.75rem;
            width: 100%;
            text-align: center;
          }
        }
      }
      .illustration {
        display: flex;
        flex: 1;
        height: 23.75rem;
        @media (max-width: ${desktopSm}px) {
          height: 15.625rem;
          flex: unset;
          width: 100%;
          max-width: 22.5rem;
        }
        @media (max-width: ${tabletMd}px) {
          width: 100%;
          max-width: 17.5rem;
          height: 11.25rem;
        }
        .left-coin {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          @media (max-width: ${desktopSm}px) {
            margin-bottom: 0.625rem;
          }
        }
        .illustration-section {
          position: relative;
          display: flex;
          flex: 1;
          .right-coin {
            position: absolute;
            right: 0;
          }
          .paper-plane {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 0.25rem 0 auto;
            @media (max-width: ${desktopSm}px) {
              justify-content: end;
            }
          }
        }
      }
    }
  `,
};
