import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import fullAmountImage from '@public/images/png/full-amount.png';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Flex,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';

const { desktopXl, tabletMd, mobileLg } = screenSize;

export default function NoHiddenFees() {
  const { t } = useTranslation('home');

  return (
    <AnimatedSection id="no-hidden-fees" viewAmount={0.35}>
      <Flex className={styled.root}>
        <div className="illustration-wrapper">
          <div className="illustration" data-nosnippet>
            <Image
              src={fullAmountImage}
              fill
              alt={t('image_alt.no_hidden_fees')}
              placeholder="blur"
              className="object-fit-contain"
              sizes="20rem"
            />
          </div>
        </div>
        <div className="full-amount">
          <Text as="h2" minSize="h4" maxSize="h2">
            {parseHtml(t('no_hidden_fees.title'))}
          </Text>
          <Text as="p" minSize="p" maxSize="h5" className="description">
            {parseHtml(t('no_hidden_fees.description'))}
          </Text>
        </div>
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    width: unset;
    max-width: 72.5rem;
    margin: 0 auto;
    padding: 8.75rem 0;
    justify-content: center;
    @media (max-width: ${desktopXl}px) {
      margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
      padding: 6.25rem clamp(2.5rem, -1.7149rem + 8.781vw, 6.188rem);
    }
    @media (max-width: ${tabletMd}px) {
      padding: 5rem 2.5rem;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: ${mobileLg}px) {
      padding: 4.5rem 1.5rem;
    }
    .illustration-wrapper {
      max-width: 29.25rem;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-right: 1.5rem;
      @media (max-width: ${tabletMd}px) {
        margin-right: unset;
        margin-bottom: 1rem;
      }
      .illustration {
        max-width: 20rem;
        width: 100%;
        aspect-ratio: 320/307;
        position: relative;
        @media (max-width: ${tabletMd}px) {
          max-width: 12.875rem;
          aspect-ratio: 1;
        }
      }
    }
    .full-amount {
      max-width: 29.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: ${tabletMd}px) {
        align-items: center;
      }
      > h2 {
        font-weight: var(--black-font-weight);
        margin-bottom: 1rem;
        text-transform: uppercase;
        @media (max-width: ${tabletMd}px) {
          text-align: center;
          margin-bottom: 0.75rem;
        }
      }
      .description {
        margin: 0;
        color: var(--neutral-500);
        @media (max-width: ${tabletMd}px) {
          text-align: center;
        }
      }
    }
  `,
};
