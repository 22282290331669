import { ServiceId } from '@api-hooks/common';
import { css } from '@emotion/css';
import { Arrow } from '@shapes';
import {
  Card,
  Flex,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import {
  SizeScreenDesktopLg,
  SizeScreenDesktopMd,
  SizeScreenTabletMd,
} from '@topremit-ui/design-tokens';
import {
  SERVICE_ICONS,
  ServiceIconName,
} from 'landing/src/components/shapes/icons.constant';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';

import { ServicesLink } from '../helper';

interface IServiceItemProps {
  serviceName: string;
  description: string;
  serviceIcon: string;
  serviceId: ServiceId;
  totalServices?: number;
}

function ServiceItem({
  serviceName,
  description,
  serviceIcon,
  serviceId,
  totalServices,
}: IServiceItemProps) {
  const { lang } = useTranslation();
  const isTabletSize = useMediaQuery(`(max-width: ${SizeScreenTabletMd}px)`);

  const imgSrc =
    SERVICE_ICONS.has(serviceIcon as any) &&
    SERVICE_ICONS.get(serviceIcon as ServiceIconName);

  function getServiceLink(serviceId: ServiceId) {
    return ServicesLink[serviceId][lang];
  }

  return (
    <Link
      href={getServiceLink(serviceId) || ''}
      className={styled.root(totalServices)}
    >
      <Card
        column
        p={24}
        variant={isTabletSize ? 'primary' : 'outline'}
        className={styled.card}
      >
        <Flex column className="service-detail">
          <div className="icon-wrapper">
            <Image src={imgSrc} fill alt={serviceIcon} sizes="3rem" />
          </div>
          <Text as="h3" minSize="h5" maxSize="h4">
            {parseHtml(serviceName)}
          </Text>
        </Flex>
        <Text as="p" minSize="sm" maxSize="p" className="description">
          {parseHtml(description)}
        </Text>
        <div className="btn-container">
          <div className="arrow-btn">
            <Arrow direction="right" size="small" fill="var(--blue-500)" />
          </div>
        </div>
      </Card>
    </Link>
  );
}

const styled = {
  root: (totalServices) => css`
    display: flex;
    flex-direction: column;
    flex-basis: ${totalServices === 5
      ? 'calc(33.3% - 1.5rem)'
      : 'calc(25% - 1.5rem)'};
    width: 100%;
    max-width: 17rem;
    height: fit-content;
    @media (max-width: ${SizeScreenDesktopLg}px) {
      flex-basis: ${totalServices === 4
        ? 'calc(50% - 1.5rem)'
        : 'calc(33.3% - 1.5rem)'};
    }
    @media (max-width: ${SizeScreenDesktopMd}px) {
      flex-basis: calc(50% - 1.5rem);
    }
    @media (max-width: ${SizeScreenTabletMd}px) {
      flex-basis: 100%;
      max-width: unset;
    }

    .service-detail {
      margin-bottom: 0.75rem;
      @media (max-width: ${SizeScreenTabletMd}px) {
        flex-direction: row;
        align-items: center;
      }
      h3 {
        margin-bottom: 0;
      }
      .icon-wrapper {
        position: relative;
        width: 3rem;
        aspect-ratio: 1;
        margin-bottom: 1.5rem;
        @media (max-width: ${SizeScreenTabletMd}px) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
    .description {
      margin: 0;
    }
    .btn-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex: 1;
      margin-top: 1rem;
      .arrow-btn {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `,
  card: css`
    min-height: 330px;
    transition: transform 0.2s ease;
    color: ${color.neutral500};
    @media (max-width: ${SizeScreenTabletMd}px) {
      min-height: unset;
    }
    :hover {
      transform: scale(1.05);
      box-shadow: 0 2px 8px rgba(46, 72, 101, 0.16);

      .arrow-btn {
        background-color: var(--blue-500);
        > svg {
          fill: var(--neutral-0);
        }
      }
    }
  `,
};

export default memo(ServiceItem);
