import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

import ServiceWhyItem from './ServiceWhyItem';

const { mobileLg, tabletMd, desktopSm, desktopXl } = screenSize;

const SERVICE_WHY_ITEM_LENGTH = 3;

export default function Why() {
  const { t } = useTranslation('home');

  function renderServiceWhyItem() {
    return (
      <ul className="list-why">
        {Array.from({ length: SERVICE_WHY_ITEM_LENGTH }).map((_, idx) => (
          <li key={idx}>
            <ServiceWhyItem idx={idx} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <AnimatedSection id="why" viewAmount={0.35} className={styled.root}>
      <Flex column className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2">
          {t('why.title')}
        </Text>
        {renderServiceWhyItem()}
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      max-width: 72.5rem;
      width: unset;
      padding: 6.25rem 0;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem
          calc(2.5rem - clamp(0rem, -2.8571rem + 5.9524vw, 2.5rem));
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      h2 {
        margin-bottom: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: var(--black-font-weight);
      }
    }
    .list-why {
      list-style: none;
      padding-left: 0;
      --grid-columns-width: minmax(auto, 23.125rem);

      width: 100%;
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, var(--grid-columns-width));
      column-gap: 1.5rem;
      @media (max-width: ${desktopSm}px) {
        row-gap: 1.5rem;
        grid-template-columns: repeat(1, var(--grid-columns-width));
      }
    }
  `,
};
