import { css } from '@emotion/css';
import { useFloatingButtonStore } from '@stores';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Accordion,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { Angle } from '@topremit/shared-web/components/shapes';

interface IServiceFaqItemProps {
  title: string;
  body: string;
}

const { tabletMd } = screenSize;

export default function ServiceFaqItem({ title, body }: IServiceFaqItemProps) {
  const { isOpenFloatingChatButton, setIsOpenFloatingChatButton } =
    useFloatingButtonStore();

  function handleOnClickContactUs(e) {
    if (e.target.id === 'floating-button' && !isOpenFloatingChatButton) {
      setIsOpenFloatingChatButton(true);
    }
  }

  return (
    <Accordion
      className={styled.root}
      title={
        <Text as="h3" minSize="sm" maxSize="h5">
          {parseHtml(title)}
        </Text>
      }
      arrowIcon={<Angle size="small" direction="down" width={24} height={24} />}
      greyBackgroundOnClose
    >
      <Text as="div" minSize="sm" maxSize="p" className="body">
        <div onClick={handleOnClickContactUs}>{parseHtml(body)}</div>
      </Text>
    </Accordion>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: ${tabletMd}px) {
      padding: 1.5rem;
    }

    .title {
      width: 100%;
      margin-right: 2rem;
      margin-bottom: 0;
    }
    .accordion-toggler-wrapper {
      align-items: flex-start;
    }
    .body {
      width: 100%;
      color: var(--text-secondary);
      margin-top: 0.75rem;
      margin-bottom: 0;
      #floating-button {
        color: var(--blue-500);
        font-weight: var(--bold-font-weight);
        &:not(:disabled) {
          &:active {
            color: var(--blue-500);
            text-decoration: underline;
          }
        }
      }
      .underline-link {
        color: var(--text-secondary);
        font-weight: var(--regular-font-weight);
      }
    }
  `,
};
