import { css } from '@emotion/css';
import { CountryModel } from '@topremit/shared-web/api-hooks/common';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flag, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import Link from 'next/link';
import { memo } from 'react';

interface ICountryItemProps
  extends Omit<
    CountryModel,
    'dialCode' | 'searchText' | 'title' | 'specialNote' | 'note' | 'continent'
  > {}

const { tabletMd } = screenSize;

function CountryItem({ flagCode, name, urlSlug }: ICountryItemProps) {
  const { t } = useTranslation();
  return (
    // workaround: using a tag to navigate to the country page and force to hard reload
    <Link href={`/send-money/${urlSlug}`} className={styled.root}>
      <div className="flag" data-nosnippet>
        <Flag
          code={flagCode}
          imgAlt={t('image_alt.country_flag', { country: name })}
        />
      </div>
      <Text breakWord={false} minSize="sm" maxSize="p">
        {name}
      </Text>
    </Link>
  );
}

const styled = {
  root: css`
    display: inline-flex;
    align-items: center;
    color: var(--text-primary);
    padding: 0.5rem 1rem;
    height: 3.75rem;
    border-radius: 1rem;
    width: 100%;
    flex-shrink: 0;
    min-width: 11.5rem;

    &:hover {
      cursor: pointer;
      background-color: var(--blue-100);
    }
    &:focus {
      color: var(--blue-500);
    }
    @media (max-width: ${tabletMd}px) {
      padding: 0 0.5rem;
    }

    .flag {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.75rem;
      > img {
        border-radius: 2px;
      }
    }
  `,
};

export default memo(CountryItem);
