import { ServiceId } from '@api-hooks/common';

export const ServicesLink = {
  [ServiceId.BANK_TRANSFER]: {
    en: 'https://blog.topremit.com/send-money-internationally-via-bank-transfer-personal/',
    id: 'https://blog.topremit.com/kirim-uang-ke-luar-negeri-layanan-bank-transfer-personal/',
  },
  [ServiceId.CASH_PICKUP]: {
    en: 'https://blog.topremit.com/send-money-internationally-via-cash-pickup-personal/',
    id: 'https://blog.topremit.com/kirim-uang-ke-luar-negeri-layanan-cash-pickup-personal/',
  },
  [ServiceId.HOME_DELIVERY]: {
    en: 'https://blog.topremit.com/send-money-internationally-via-home-delivery-personal/',
    id: 'https://blog.topremit.com/kirim-uang-ke-luar-negeri-dengan-layanan-home-delivery/',
  },
  [ServiceId.EWALLET]: {
    en: 'https://blog.topremit.com/send-money-internationally-via-e-wallet-personal/',
    id: 'https://blog.topremit.com/kirim-uang-ke-luar-negeri-via-layanan-e-wallet-personal/',
  },
  [ServiceId.SWIFT]: {
    en: 'https://blog.topremit.com/send-money-internationally-via-swift-personal/',
    id: 'https://blog.topremit.com/kirim-uang-ke-luar-negeri-via-layanan-swift-personal/',
  },
};
