import { css } from '@emotion/css';
import EasyEfficientImage from '@public/images/png/send-money-easy-efficient.png';
import FasterThanEverImage from '@public/images/png/send-money-faster-than-ever.png';
import SaveMoreImage from '@public/images/png/send-money-save-more.png';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Flex,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';

interface IServiceWhyItemProps {
  idx: number;
}

const { desktopSm } = screenSize;
const illustrations = [EasyEfficientImage, SaveMoreImage, FasterThanEverImage];

export default function ServiceWhyItem({ idx }: IServiceWhyItemProps) {
  const { t } = useTranslation('home');
  return (
    <Flex column className={styled.root}>
      <Flex align="center" justify="center" className="icon" data-nosnippet>
        <Image
          src={illustrations[idx]}
          width={150}
          height={120}
          placeholder="blur"
          alt={t(`image_alt.why.${idx + 1}`)}
          className="object-fit-contain"
        />
      </Flex>
      <Text as="h3" minSize="h5" maxSize="h4" className="title">
        {t(`why.content.title_${idx + 1}`)}
      </Text>
      <Text as="p" minSize="p" maxSize="h5" className="desc">
        {parseHtml(t(`why.content.desc_${idx + 1}`))}
      </Text>
    </Flex>
  );
}

const styled = {
  root: css`
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    .icon {
      padding: 3.5rem 2.5rem;
      @media (max-width: ${desktopSm}px) {
        padding: 1.375rem 0.4375rem;
      }
    }
    .title {
      margin-bottom: 0.5rem;
      @media (max-width: ${desktopSm}px) {
        margin-bottom: 0.25rem;
      }
    }
    .desc {
      color: var(--neutral-500);
      margin: 0;
    }
  `,
};
