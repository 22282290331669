import { useGetLandingPageInfo } from '@api-hooks/common';
import { css, cx } from '@emotion/css';
import imgBiLicenseEn from '@public/images/png/bi-license-en.png';
import imgBiLicenseId from '@public/images/png/bi-license-id.png';
import coinBackgroundLeft from '@public/images/png/coin-send-money-left.png';
import coinBackgroundRight from '@public/images/png/coin-send-money-right.png';
import paperPlaneBackground from '@public/images/png/paper-plane-send-money-with-line.png';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Button,
  Card,
  Container,
  Flex,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { Appstore, PlayStore } from '@topremit/shared-web/components/shapes';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

import { CalculatorRemit } from '.';

const { desktopMd, tabletMd, tabletSm, mobileLg } = screenSize;

const lisencedByURL =
  'https://www.bi.go.id/id/qrcode/validate.aspx?tipe=PTD&id=0010.001/0001';

const imgBiLicense = {
  en: imgBiLicenseEn,
  id: imgBiLicenseId,
};

function Jumbotron() {
  const { t, lang } = useTranslation('home');
  const isMobileSize = useMediaQuery(`(max-width: ${mobileLg}px)`);
  const { data } = useGetLandingPageInfo();

  const [baseUrl, setBaseUrl] = useState<string>('');

  const ref = useRef(null);

  useEffect(() => {
    setBaseUrl(window.location.origin);
  }, []);

  const isFirstTransactionFree = data?.data.isFirstTransactionFree;

  return (
    <section id="calculator">
      <div className={styled.landingJumbotron}>
        <Container>
          <Flex className="container-left">
            <Text as="h1" minSize="h3" maxSize="h1">
              {t('jumbotron.headline')}
            </Text>
            <Text minSize="sm" maxSize="h4" className="headline-content">
              {parseHtml(t('jumbotron.headline_content'))}
            </Text>
            <div className="landing-button-wrapper">
              <Link href={`${baseUrl}/${lang}/register/create-account`}>
                <Button size={isMobileSize ? 'medium' : 'large'}>
                  {t('jumbotron.action.register_now')}
                </Button>
              </Link>
            </div>
            <Flex className="download-platform-wrapper">
              <Link
                className="svg"
                target="_blank"
                href="https://topremit.onelink.me/9tlR/821f56ae"
                rel="nofollow"
                prefetch={false}
              >
                <PlayStore />
              </Link>
              <Link
                className="svg"
                target="_blank"
                href="https://link.topremit.com/9tlR/f3bf1ac8"
                rel="nofollow"
                prefetch={false}
              >
                <Appstore />
              </Link>
            </Flex>
            <Flex className="lisencedby-wrapper">
              <Text as="span" minSize="xs" maxSize="p">
                {t('send-money:jumbotron.licensed_by')}
              </Text>
              <Link
                target="_blank"
                href={lisencedByURL}
                rel="nofollow"
                prefetch={false}
                className="bi-logo"
              >
                <Flex data-nosnippet>
                  <Image
                    src={imgBiLicense[lang === 'default' ? 'en' : lang]}
                    alt={t('image_alt.licensed_by')}
                    width={136}
                    height={30}
                    style={{ objectFit: 'cover' }}
                  />
                </Flex>
              </Link>
            </Flex>
          </Flex>
          <div className="paper-plane">
            <Image
              priority
              src={paperPlaneBackground}
              placeholder="blur"
              alt="paper-plane"
              className="paper-plane-img"
            />
          </div>
          <Flex className="container-right">
            <div id="root-calculator-remit">
              <div className="coin-above">
                <Image
                  priority
                  src={coinBackgroundLeft}
                  placeholder="blur"
                  alt="coin-above"
                  className="coin-above-img"
                />
              </div>

              <div
                className={cx('calculator-highlight', {
                  visible: isFirstTransactionFree,
                })}
              >
                <div className="calculator-highlight-text">
                  {t('jumbotron.calculator.highlight')}
                </div>
                <div className="calculator-highlight-background" />
              </div>

              <Card column p={24} className="calculator-remit-container">
                <CalculatorRemit targetRef={ref} />
                <div ref={ref} style={{ width: '100%', marginTop: '2rem' }} />
              </Card>

              <div className="coin-below">
                <Image
                  priority
                  src={coinBackgroundRight}
                  placeholder="blur"
                  alt="coin-below"
                  className="coin-below-img"
                />
              </div>
            </div>
          </Flex>
        </Container>
      </div>
    </section>
  );
}

const styled = {
  landingJumbotron: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 8.75rem;
      padding-bottom: 18.8718rem;
      gap: 5rem;

      @media (max-width: ${desktopMd}px) {
        flex-direction: column;
        padding-top: 9.75rem;
        padding-bottom: 2.5rem;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 9.75rem 0 4.5rem 0;
        width: 100%;
        margin: 0;
      }
      .container-left {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        z-index: 2;
        @media (max-width: ${desktopMd}px) {
          max-width: unset;
        }
        @media (max-width: ${mobileLg}px) {
          padding: 0 1.5rem;
        }
        > h1 {
          width: 100%;
          margin-bottom: 1rem;
          font-weight: var(--black-font-weight);
          text-transform: uppercase;
          @media (max-width: ${tabletMd}px) {
            margin-top: 0.5rem;
          }
        }
        .headline-content {
          width: 100%;
          color: var(--neutral-500);
          margin-bottom: 2.5rem;
          @media (max-width: ${mobileLg}px) {
            margin-bottom: 1rem;
          }
        }

        .landing-button-wrapper {
          margin-bottom: 2.5rem;
          @media (max-width: ${mobileLg}px) {
            margin-bottom: 3rem;
          }
        }
        .download-platform-wrapper {
          margin-bottom: 1.5rem;
          @media (max-width: ${mobileLg}px) {
            margin-bottom: 1rem;
          }
          > .svg {
            width: 6.25rem;
            height: 2.0625rem;
            &:first-of-type {
              margin-right: 1rem;
            }
          }
        }
        .lisencedby-wrapper {
          align-items: center;

          > span {
            margin-right: 0.5rem;
            color: var(--neutral-500);
          }
          .bi-logo {
            padding: 0.25rem;
          }
        }
      }
      .paper-plane {
        position: absolute;
        left: -149px;
        bottom: 105px;
        z-index: -1;
        .paper-plane-img {
          width: 784.24px;
          height: 198.52px;
        }
        @media (max-width: ${desktopMd}px) {
          display: none;
        }
      }
      .container-right {
        position: relative;
        z-index: 1;
        width: unset;
        justify-content: center;
        @media (max-width: ${desktopMd}px) {
          position: relative;
          left: unset;
          align-items: center;
        }
        @media (max-width: ${mobileLg}px) {
          margin: 0 0.5rem;
        }

        #root-calculator-remit {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          min-height: 21rem;
          > div:first-child {
            @media (max-width: ${tabletMd}px) {
              min-width: 24.625rem;
              max-width: 24.625rem;
            }
            @media (max-width: ${tabletSm}px) {
              min-width: 0;
            }
          }
          .select-country-landing {
            .form-label {
              left: 0 !important;
            }
          }

          .calculator-highlight.visible {
            opacity: 1;
            padding: 0.5rem 0;
            overflow-y: visible;
            height: auto;
          }

          .calculator-highlight {
            height: 0;
            opacity: 0;
            overflow-y: hidden;
            transition: all 0.5s;

            border-radius: 16px;
            width: 24.625rem;
            position: relative;

            @media (max-width: ${tabletSm}px) {
              width: 100%;
              max-width: 26.25rem;
            }

            .calculator-highlight-text {
              text-align: center;
              color: var(--link-active-text);
              font-weight: bold;
              font-size: 0.875rem;
            }

            .calculator-highlight-background {
              width: 100%;
              height: 120px;
              border-radius: 16px;

              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              z-index: -1;

              background-color: var(--blue-100);
              background-image: url('/images/png/calculator-highlight-background.jpg');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            }
          }

          .calculator-remit-container {
            align-items: center;
            justify-content: space-between;

            width: 24.625rem;
            z-index: 999;

            @media (max-width: ${tabletSm}px) {
              width: 100%;
              max-width: 26.25rem;
            }
          }
        }
        .coin-above,
        .coin-below {
          @media (max-width: ${desktopMd}px) {
            display: none;
          }
        }
        .coin-above {
          z-index: -1;
          position: absolute;
          top: 100px;
          left: -65px;
          .coin-above-img {
            width: 83.52px;
            height: 74.57px;
          }
        }
        .coin-below {
          z-index: -1;
          position: absolute;
          top: 372px;
          right: -72px;
          .coin-below-img {
            width: 100.18px;
            height: 93.21px;
          }
        }
      }
    }
  `,
};

export default Jumbotron;
