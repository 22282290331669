import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import newsMedia1Image from '@public/images/png/news-media-1.png';
import newsMedia10Image from '@public/images/png/news-media-10.png';
import newsMedia2Image from '@public/images/png/news-media-2.png';
import newsMedia3Image from '@public/images/png/news-media-3.png';
import newsMedia4Image from '@public/images/png/news-media-4.png';
import newsMedia5Image from '@public/images/png/news-media-5.png';
import newsMedia6Image from '@public/images/png/news-media-6.png';
import newsMedia7Image from '@public/images/png/news-media-7.png';
import newsMedia8Image from '@public/images/png/news-media-8.png';
import newsMedia9Image from '@public/images/png/news-media-9.png';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';
import Link from 'next/link';

const { desktopXl, tabletMd, mobileLg } = screenSize;

const newsMedia = [
  {
    url: 'https://www.forbes.com/profile/topremit/?sh=3ecfc9e2bc60',
    image: newsMedia1Image,
  },
  {
    url: 'https://www.instagram.com/p/CM1VK7Spxaw/',
    image: newsMedia2Image,
  },
  {
    url: 'https://mediaindonesia.com/ekonomi/544450/perusahaan-remitansi-topremit-raih-penghargaan-dari-bank-indonesia',
    image: newsMedia3Image,
  },
  {
    url: 'https://finance.detik.com/advertorial-news-block/d-6525955/kirim-uang-ke-luar-negeri-pakai-aplikasi-topremit-banyak-untungnya',
    image: newsMedia4Image,
  },
  {
    url: 'https://ekonomi.bisnis.com/read/20230417/12/1647494/topremit-catat-transaksi-remitansi-rp76-triliun-usai-14-tahun-berdiri',
    image: newsMedia5Image,
  },
  {
    url: 'https://www.cnnindonesia.com/gaya-hidup/20220922211904-307-851501/topremit-cara-terbaik-kirim-uang-ke-luar-negeri',
    image: newsMedia6Image,
  },
  {
    url: 'https://www.liputan6.com/lifestyle/read/5242869/14-tahun-jadi-andalan-jasa-pengiriman-uang-ke-luar-negeri',
    image: newsMedia7Image,
  },
  {
    url: 'https://investor.id/finance/323720/permudah-transaksi-bisnis-topremit-luncurkan-layanan-transfer-dolar-as-ke-tiongkok',
    image: newsMedia8Image,
  },
  {
    url: 'https://www.tribunnews.com/bisnis/2022/12/16/beroperasi-sejak-2009-perusahaan-remitansi-ini-sudah-layani-160-ribu-pengguna',
    image: newsMedia9Image,
  },
  {
    url: 'https://www.cnbcindonesia.com/tech/20221219135948-37-398152/kirim-uang-lintas-negara-dengan-aman-mudah-gini-caranya',
    image: newsMedia10Image,
  },
];

export default function FeaturedOn() {
  const { t } = useTranslation('home');

  function renderIllustrations() {
    return (
      <ul>
        {newsMedia.map((media, idx) => (
          <li key={idx}>
            <Link
              rel="nofollow"
              target="_blank"
              href={media.url}
              className="illustration-item"
              prefetch={false}
            >
              <div className="img-wrapper" data-nosnippet>
                <Image
                  src={media.image}
                  placeholder="blur"
                  alt={t(`image_alt.news.${idx + 1}`)}
                  fill
                  className="object-fit-contain"
                  sizes="8.25rem"
                />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <AnimatedSection id="featured-on" viewAmount={0.35} className={styled.root}>
      <Flex column className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2" className="title">
          {t('about_us.title')}
        </Text>
        <div className="illustrations-wrapper">{renderIllustrations()}</div>
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      align-items: center;
      width: unset;
      max-width: 71.5rem;
      padding: 6.25rem 0;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem clamp(2.5rem, -1.7149rem + 8.781vw, 6.188rem);
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
        align-items: center;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      .title {
        text-align: center;
        text-transform: uppercase;
        font-weight: var(--black-font-weight);
        margin-bottom: 2.5rem;
      }
      .illustrations-wrapper {
        width: 100%;
        align-items: center;
        justify-content: center;
        ul {
          list-style: none;
          padding-left: 0;
          display: flex;
          flex-wrap: wrap;
          row-gap: 3rem;
          column-gap: 1rem;
          justify-content: center;
          &:hover {
            cursor: pointer;
          }
          .illustration-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13.5rem;
            height: 5rem;
            @media (max-width: ${tabletMd}px) {
              width: 6.75rem;
              height: 1.875rem;
            }
            @media (max-width: ${mobileLg}px) {
              width: 9rem;
              height: 2rem;
            }
            .img-wrapper {
              position: relative;
              height: 100%;
              width: 100%;
              max-width: 8.25rem;
              max-height: 3rem;
              filter: brightness(0) saturate(100%) invert(65%) sepia(7%)
                saturate(858%) hue-rotate(164deg) brightness(89%) contrast(94%);
              &:hover {
                filter: unset;
              }
            }
          }
        }
      }
    }
  `,
};
