import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex } from '@topremit/shared-web/components/elements';

import AwardItem from './AwardItem';

const { mobileLg, tabletSm, tabletMd, desktopXl } = screenSize;

const AWARD_ITEM_LENGTH = 3;

export default function Award() {
  function renderAwardItem() {
    return (
      <ul className="award-items">
        {Array.from({ length: AWARD_ITEM_LENGTH }).map((_, idx) => (
          <li key={`awards-${idx}`}>
            <AwardItem idx={idx} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <AnimatedSection id="award" viewAmount={0.35} className={styled.root}>
      <Flex className="inner-container">{renderAwardItem()}</Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: css`
    .inner-container {
      padding: 6.25rem 0;
      margin: 0 auto;
      width: unset;
      max-width: 72.375rem;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem
          calc(2.5rem - clamp(0rem, -2.8571rem + 5.9524vw, 2.5rem));
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      .award-items {
        list-style: none;
        padding-left: 0;
        display: flex;
        width: 100%;
        @media (max-width: ${tabletSm}px) {
          flex-direction: column;
        }
        > :not(:last-child) {
          margin-right: 1.5rem;
          @media (max-width: ${tabletSm}px) {
            margin-right: 0;
            margin-bottom: 3rem;
          }
        }
      }
    }
  `,
};
