import { ServiceId } from '@api-hooks/common';
import { useGetAvailableServices } from '@api-hooks/transaction';
import { AnimatedSection } from '@elements';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

import ServiceItem from './ServiceItem';

const { desktopXl, desktopLg, tabletMd, mobileLg } = screenSize;

export default function Services() {
  const { t } = useTranslation();

  const { data: availableServices } = useGetAvailableServices();
  const availableServicesData = availableServices?.data || [];

  function renderServiceItem() {
    return (
      <ul className="services-list">
        {availableServicesData.map((availableService, idx) => {
          const domesticService =
            availableService.serviceId === 12 ||
            availableService.serviceId === 14;

          if (
            !Object.values(ServiceId).includes(availableService.serviceId) ||
            domesticService
          ) {
            return null;
          }

          return (
            <li key={`service-keys-${idx}`}>
              <ServiceItem
                serviceName={availableService.serviceName}
                description={availableService.description}
                serviceIcon={availableService.serviceIcon}
                serviceId={availableService.serviceId}
                totalServices={availableServicesData.length}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <AnimatedSection
      id="services"
      viewAmount={0.35}
      className={styled.root(availableServicesData.length)}
    >
      <Flex column className="inner-container">
        <Text as="h2" minSize="h4" maxSize="h2">
          {t('home:services.title')}
        </Text>
        {renderServiceItem()}
      </Flex>
    </AnimatedSection>
  );
}

const styled = {
  root: (totalServices) => css`
    .inner-container {
      max-width: 72.5rem;
      width: unset;
      padding: 6.25rem 6.1875rem;
      margin: 0 auto;
      @media (max-width: ${desktopXl}px) {
        margin: 0 clamp(0rem, -10rem + 20.8333vw, 8.75rem);
        padding: 6.25rem clamp(2.5rem, -1.7149rem + 8.781vw, 6.188rem);
      }
      @media (max-width: ${tabletMd}px) {
        padding: 2.5rem;
        flex-direction: column;
        align-items: center;
      }
      @media (max-width: ${mobileLg}px) {
        padding: 4.5rem 1.5rem;
      }
      h2 {
        text-transform: uppercase;
        text-align: center;
        font-weight: var(--black-font-weight);
        margin-bottom: 2.5rem;
      }
      .services-list {
        list-style: none;
        padding-left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
        @media (max-width: ${desktopLg}px) {
          max-width: ${totalServices === 4 ? '50rem' : 'unset'};
        }
        @media (max-width: ${tabletMd}px) {
          max-width: 27.8125rem;
          > li {
            width: 100%;
          }
        }
      }
    }
  `,
};
